
import { Component, Vue } from 'vue-property-decorator'
import { KeepAlive } from '@/utils/decorators'

@Component
@KeepAlive
export default class List extends Vue {
    private page = 1
    private size = 10
    private total = 0
    private loading = false
    private tableData = []
    private dateRange: Array<string> = []
    private searchInfo = {
      projectId: '',
      deviceName: '',
      deviceNumber: '',
      startTime: '',
      endTime: ''
    }

    get projectList () {
      return this.$store.state.projectList
    }

    created () {
      this.loadData()
    }

    // 获取表格
    loadData () {
      this.loading = true
      this.$axios.get(this.$apis.ecologyMonitor.selectSoilWarningByPage, {
        page: this.page,
        size: this.size,
        ...this.searchInfo
      }).then(res => {
        this.tableData = res.list
        this.total = res.total
      }).finally(() => {
        this.loading = false
      })
    }

    dateChange (date: string[]) {
      if (date) {
        this.searchInfo.startTime = date[0]
        this.searchInfo.endTime = date[1]
      } else {
        this.searchInfo.startTime = ''
        this.searchInfo.endTime = ''
      }
    }

    // 查询
    onSearch () {
      this.page = 1
      this.loadData()
    }

    getState (state: string) {
      const arr = ['', '未开工', '在建', '已移交']
      return arr[Number(state)]
    }
}
